import mediumZoom from "@wagich/medium-zoom";
import Flickity from "flickity";

export class Gallery {
	private titleSheet: HTMLElement;
	private flickity: Flickity;

	constructor(container: HTMLElement) {
		let imageContainer = container.querySelector(".asset-list")!;
		this.titleSheet = container.querySelector<HTMLElement>(".gallery-block__title-sheet")!;

		let firstImageClone = imageContainer.firstChild?.cloneNode(true)!;
		imageContainer.appendChild(firstImageClone);

		this.flickity = new Flickity(imageContainer, {
			pageDots: false,
			cellAlign: "left",
			setGallerySize: false,
			contain: true,
			lazyLoad: 3,
			arrowShape: "M68.79 9.33L42.87 49.65 68.79 90l-10.56 7.3-33.6-41.51V43.7L58.23 2z"
		});

		this.flickity.on("staticClick", this.zoomImage.bind(this));
		this.flickity.on("change", this.onFlickityChange.bind(this));

		let startButton = container.querySelector<HTMLElement>(".gallery-block__start-button");
		startButton?.addEventListener("click", this.start.bind(this));
	}

	start(e: MouseEvent) {
		this.flickity.next();
		this.flickity.playPlayer();
	}

	zoomImage(e?: Event) {
		let target = e?.target as HTMLElement;
		window.setTimeout(() => {
			let zoom = mediumZoom(target);
			zoom.open({ target });
			zoom.detach(target);
		}, 0);
	}

	onFlickityChange(index?: number) {
		index ||= 0;

		if (index === 0) {
			this.titleSheet?.classList.remove("is-collapsed");
			this.flickity.stopPlayer();
		} else {
			this.titleSheet?.classList.add("is-collapsed");
		}
	}
}
